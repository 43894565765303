@import "~@scuf/styles/variables.scss";
@import "../styleVariables.scss";

.loader-content,
.login-content {
  height: 100%;
}

.login-content {
  form {
    width: 100%;
  }
  .slider {
    // ** Transition property defined in MultiSelect.tsx **
    width: 100%;
    max-height: $multi-select-height;
    overflow: hidden;
    &.closed {
      max-height: 0;
    }
  }

  h1 {
    @include hcui-h1;
    width: 100%;
    margin-top: 3.25rem;
  }

  .login-input {
    width: 100%;
    
    &:focus {
      outline: none;
    }
  }

  // .login-input:not(.error),
  // .multi-select-wrapper .ui.search.selection.dropdown:not(.error) {
  //   margin-bottom: $hcui-spacing-lg;
  // }

  .login-button {
    margin-top: $hcui-spacing-2xs;
    width: 100%;
  }

  .support-button {
    width: 100%;
    margin: auto;
    margin-top: $hcui-spacing-md;
  }

  .support-button-spacer {
    height: 1.5rem;
  }

  .footer {
    color: $hcui-color-text-low-contrast;
    font-size: 0.75rem;
    text-align: center;
    margin-top: $hcui-spacing-4xl;
  }
}