@import '~@scuf/styles/variables.scss';

.continue-as-content {
	h1 {
    @include hcui-h1;
    text-align: center;
  }
  
  h2 {
    color: $hcui-color-text-low-contrast;
    font-size: 1rem;
    margin-top: 0;
    font-weight: 300;
  }
	
	.user-avatar {
    width: 120px;
    height: 120px;
    
		display: block;
    border-radius: 50%;
    
    margin-bottom: $hcui-spacing-xl;
  }
  
  .forget-me {
    margin-top: $hcui-spacing-3xl;
  }
  
  .continue-as-button {
    width: 100%;
  }
}