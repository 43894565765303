@import "~@scuf/styles/variables.scss";
@import "../styleVariables.scss";

.centered-card-wrapper {
  height: 100vh;
  width: 100vw;
  min-height: $login-card-height;

  display: flex;
  justify-content: center;
  align-items: center;

  .centered-card {
    width: 100%;
    max-width: 570px;

    height: auto;
    box-shadow: $hcui-overlay-shadow !important;
    padding-bottom: $hcui-spacing-3xl;

    .centered-card-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      padding: $hcui-spacing-3xl * 2 $hcui-spacing-3xl * 2 $hcui-spacing-xl;

      @media only screen and (max-width: 570px) {
        padding: $hcui-spacing-xl $hcui-spacing-xl $hcui-spacing-lg;
      }

      .logo {
        display: flex;
        align-items: center;

        img {
          display: block;
          width: 100%;
        }
      }
    }
  }
}