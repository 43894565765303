@import "~@scuf/styles/variables.scss";
@import "../styleVariables.scss";

// This must overwrite the overflow property
.login-content form .slider.multi-select-wrapper.overflow-visible {
  overflow: visible;
}

.multi-select-wrapper {
  width: 100%;
  .ui.input-label {
    width: 100%;
    margin-bottom: $hcui-spacing-sm;
  }
  .multi-select {
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
    margin-bottom: $hcui-spacing-lg;
    text-transform: capitalize;
    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      margin-right: 1rem;
      &:last-of-type {
        margin-right: 0;
      }
      transition-duration: 0.3s;
      height: 45px;
      width: 116px;
      &.scuf-col.scuf-grid.col-4 {
        margin: 0.3rem;
      }
      &:hover {
        transform: scale(1.02);
      }
      box-shadow: inset 0px 0px 0px 10px #f00;

      border: 1px solid $button-primary-color-disabled !important;
      cursor: pointer;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
      div {
        font-size: $hcui-font-size-body;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;
        padding: $hcui-spacing-3xs;
        font-size: $hcui-spacing-md - $hcui-spacing-3xs;
      }
      &.selected {
        border: 1px solid $hcui-color-primary !important;
      }
      &:hover {
        box-shadow: $hcui-pop-out-shadow;
      }
    }
  }
}
.multi-select-spacer {
  height: $multi-select-height;
}
